//import router from './utils/router'
//import domManager from './utils/domManager'





//framework hooks
window.hasCustomStore = function (url, module) {

    module = module.toLowerCase();
    url = url.toLowerCase();
    switch (module) {
        case ("trip"):
            if (url.endsWith("?table=gear")) return true;
            break;
    }
    return false;
}
window.customStore = function (url, module, parentQs, customQs, params) {
    // todo add logic here

    url = url.toLowerCase();
    module = module.toLowerCase();
    var urlSuffix = "&listModule=" + module + parentQs + customQs;
    var data = $("#details-form-container").dxForm("instance").option('formData');
    var table = url.split("?table=").slice(-1)[0];

    switch (table) {
        case "gear":
            var voa = data["VesselOwnerAggregatedID"];
            return $.getJSON("../api/project/getgearforvesselowner?voa=" + voa + urlSuffix, params);
    }

    return false;
}

window.customFormFieldChange = function (formField, event) {
    var form = $("#details-form-container").dxForm("instance");
    var items = $("#details-form-container").dxForm("instance").option('items');
    var data = $("#details-form-container").dxForm("instance").option('formData');


    if (window.currentModule.toLowerCase() === "gear") {

        switch (event.dataField) {
            case "GearTypeID":
                var alwaysShow = ["Info", "Default", "Number", "Code", "VesselSideID", "GearTypeID"];
                var showTbbDef = ["GearSubTypeID", "GearStimulationTypeID", "BeamWidth", "NumberOfGears", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
                var showTbbCru = ["GearSubTypeID", "GearStimulationTypeID", "BeamWidth", "NumberOfGears", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
                var showOtbDef = ["GearSubTypeID", "GearStimulationTypeID", "NetSpread", "NumberOfGears", "NumberOfNetsPerGear", "BoardSpread", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
                var showOtbMcd = ["GearSubTypeID", "GearStimulationTypeID", "NetSpread", "NumberOfGears", "NumberOfNetsPerGear", "BoardSpread", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
                var showOtbCru = ["GearSubTypeID", "GearStimulationTypeID", "NetSpread", "NumberOfGears", "NumberOfNetsPerGear", "BoardSpread", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
                var showPassive = ["GearSubTypeID", "GearStimulationTypeID", "SoakTime"];
                var showDrbMol = ["GearSubTypeID", "GearStimulationTypeID", "GearWidth", "NumberOfDregs", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
                var showSsc = ["GearSubTypeID", "GearStimulationTypeID", "LengthSeineRope", "DiameterSeineRope", "CodEndMeshTypeID", "CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];

                sharedFunctions.showLoadPanel();

                for (var i = 0; i < items.length; i++) {
                    if (items[i].dataField === "ID" || items[i].dataField === "VesselOwnerAggregatedID") {
                        continue;
                    }
                    form.itemOption(items[i].dataField, "visible", false);
                    if (alwaysShow.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                        continue;
                    }
                    var val = data.GearTypeID;
                    if (val === null) {
                        break;
                    }
                    else if (val.toUpperCase() === "2834D1EF-B5FB-4F93-BB50-094689329084" && showTbbDef.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "4D40DBFC-4DBC-492D-8C02-8BD3DBDBC02A" && showTbbCru.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "17251129-8C37-44B2-B29C-9B143F90F9F0" && showOtbDef.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "C8EEE5E4-61D4-4BB7-AAAB-1E3C712F1E89" && showOtbMcd.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "C29F9475-F589-4004-99F7-E265DFF905AD" && showOtbCru.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "07DCD8AA-8C3A-4DF0-825D-C7572DB3AF3F" && showPassive.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "D3967DDD-1C54-432E-8977-EF55073D2637" && showDrbMol.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                    else if (val.toUpperCase() === "D095A06C-9E97-48B5-9D16-EB9EFD3861FD" && showSsc.includes(items[i].dataField)) {
                        form.itemOption(items[i].dataField, "visible", true);
                    }
                }

                for (var i = 0; i < items.length; i++) {
                    if (items[i].dataField === "ID" || items[i].dataField === "VesselOwnerAggregatedID") {
                        continue;
                    }
                    var isVisible = form.itemOption(items[i].dataField).visible;
                    if (!isVisible) {
                        var obj = findFormField(items, items[i].dataField);
                        obj.value = null;
                        form.updateData(items[i].dataField, obj.value);
                    }
                }

                sharedFunctions.hideLoadPanel();
        }
    }

}

window.handleCustomValidation = function (detailsComponent, errorObj) {



}

var nbbConsultSettings = null;

window.listPageRendered = function (module) {
    //voor eerste keer inladen
    selectOnlyRow();

    var grid = sharedFunctions.getGrid();

    // telkens de grid z'n content aanpast (vb door een search)
    grid.on({
        "contentReady": selectOnlyRow,
    });
    switch (module) {
        case ("VesselOwnerAggregated"):
            nbbConsultSettings = getNbbConsultUrl();
            break;
        case "MyVessel":
        case "Trip":
            $("#btn-create").hide();
            $("#btn-edit").hide();
            $("#btn-delete").hide();
            break;
        default:
            $("#btn-create").show();
            $("#btn-edit").show();
            $("#btn-delete").show();
            break;
        
    }

}

window.detailsPageRendered = function (module) {

    $("#btn-next").hide();
    $("#btn-previous").hide();

    switch (module.toLowerCase()) {
        case ("gear"):
            var meshSizeFields = ["CodEndMeshSize", "MeshSizeEnd", "MeshSizeCap", "MeshSizeBelly", "MeshSizeBack"];
            meshSizeFields.forEach(function (field) {
                $("#details-form-container").dxForm("instance").option('items').find(o => o.dataField === field).editorOptions.buttons = [{
                    name: 'info-maas',
                    location: 'after',
                    options: {
                        icon: 'help',
                        stylingMode: 'text',
                        width: 32,
                        onClick(e) {
                            if ($('#popup-container').length === 0) {
                                $('#appContent').append('<div id="popup-container"></div>');
                            }
                            $("#popup-container").dxPopup({
                                title: "Info maaswijdte",
                                closeOnOutsideClick: true,
                                showCloseButton: true,
                                width: 1100,
                                height: 600,
                                contentTemplate: function () {
                                    return $("<div><img src='project/img/NetMazen.png' /></div>");
                                }
                            });
                            $("#popup-container").dxPopup("show");
                        },
                    }
                }, 'clear', 'spins'];

            });


            $("#details-form-container").dxForm("instance").repaint();
            break;
    }

}

function selectOnlyRow() {
    var grid = sharedFunctions.getGrid();
    var numberOfRows = grid.getVisibleRows();
    if (numberOfRows.length === 1) {
        grid.selectRowsByIndexes([0]);
    }
}

function getNbbConsultUrl() {
    let settings = null;
    $.ajax({
        url: '/api/project/getnbbconsultsettings',
        type: 'GET',
        async: false
    }).done(function (result) {
        settings = result;
    });

    return settings;
}

function fileUploadValduvis(type) {
    if ($('#' + type + '-popup-container').length === 0) {
        $('#appContent').append('<div id="' + type + '-popup-container"></div>');
    }

    const popupContentTemplate = function () {
        //return $('<div>').append($(`<input id="loader-file-uploader" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="fileupload" />`));
        return $('<div>').append($('<div id="' + type + '-year" /><div id="' + type + '-file-uploader" />'));
    };

    var files = [];
    var year = new Date().getFullYear() - 1;
    var qs = sharedFunctions.queryStringToJsonObj(window.location.hash.substr(window.location.hash.lastIndexOf('?') + 1));

    const popup = $('#' + type + '-popup-container').dxPopup({
        contentTemplate: popupContentTemplate,
        width: 500,
        height: 325,
        container: 'document',
        showTitle: true,
        title: 'Upload file',
        visible: false,
        dragEnabled: true,
        closeOnOutsideClick: false,
        showCloseButton: false,
        position: 'center',
        shading: true,
        shadingColor: 'rgba(245, 40, 145, 0)',
        toolbarItems: [
            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: 'Upload',
                    onClick() {

                        if (files.length !== 1) {
                            sharedFunctions.showMessage('Please choose a file first', 'Upload error');
                            return;
                        }


                        var tmpPanel = $(".custom-loadpanel").dxLoadPanel({
                            shadingColor: "rgba(0,0,0,0)",
                            position: { of: "#ilvoBody" },
                            visible: false,
                            message: "Starting upload",
                            showIndicator: true,
                            showPane: true,
                            shading: true,
                            closeOnOutsideClick: false,
                        }).dxLoadPanel("instance");

                        tmpPanel.show();

                        var fd = new FormData();
                        //fd.append('files', $('#loader-file-uploader')[0].files[0]);
                        fd.append('files', files[0]);

                        $.ajax({
                            url: '../api/project/upload?voa=' + qs["bc:VesselOwnerAggregated"] + '&year=' + year + '&type=' + type,
                            type: 'Post',
                            data: fd,
                            enctype: 'multipart/form-data',
                            processData: false,
                            contentType: false,
                            dataType: "json"
                        }).done(function (result) {
                            if (result.Error) {
                                sharedFunctions.showMessage(result.Error, 'Upload error');
                                return;
                            } else {
                                location.reload();
                            }
                        }).fail(function (xhr, textStatus, errorThrown) {
                            sharedFunctions.showMessage(xhr.responseText, textStatus);
                        }).always(function () {
                            tmpPanel.hide();
                            popup.hide();
                        });

                    }
                },
            },
            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: "Sluit",
                    onClick() {
                        popup.hide();
                    },
                },
            }],

    }).dxPopup('instance');

    popup.show();

    $('#' + type + '-year').dxNumberBox({
        value: new Date().getFullYear() - 1,
        min: 2020,
        max: new Date().getFullYear(),
        showSpinButtons: true,
        label: "Year",
        onValueChanged: function (args) {
            year = args.value;
        }
    });

    var accept = '.html';
    if (type === "verbeter") {
        accept = '.pdf';
    }

    $('#' + type + '-file-uploader').dxFileUploader({
        //selectButtonText: 'Select photo',
        //labelText: '',
        accept: accept,
        //allowedFileExtensions: accept,
        uploadMode: 'useButton',
        onValueChanged: function (args) {
            files = args.value;
        }
    });
}


//event handlers 
window.addEventListener("customAction-Start-Loaders", eventHandler1);
function eventHandler1(e, control) {
    var selectedData = sharedFunctions.getSelectedData();
    if (!selectedData || selectedData.length !== 1 || !selectedData[0].ID) {
        sharedFunctions.showMessage('Please choose exactly 1 loader', 'Import error');
        return;
    }

    if ($('#loader-popup-container').length === 0) {
        $('#appContent').append('<div id="loader-popup-container"></div>');
    }

    const popupContentTemplate = function () {
        //return $('<div>').append($(`<input id="loader-file-uploader" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" name="fileupload" />`));
        return $('<div>').append($(`<div id="loader-file-uploader" />`));
    };

    var files = [];

    const popup = $('#loader-popup-container').dxPopup({
        contentTemplate: popupContentTemplate,
        width: 500,
        height: 300,
        container: 'document',
        showTitle: true,
        title: 'Import',
        visible: false,
        dragEnabled: true,
        closeOnOutsideClick: false,
        showCloseButton: false,
        position: 'center',
        shading: true,
        shadingColor: 'rgba(245, 40, 145, 0)',
        toolbarItems: [
            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: 'Start',
                    onClick() {

                        if (files.length !== 1) {
                            sharedFunctions.showMessage('Please choose a file first', 'Import error');
                            return;
                        }


                        var tmpPanel = $(".custom-loadpanel").dxLoadPanel({
                            shadingColor: "rgba(0,0,0,0)",
                            position: { of: "#ilvoBody" },
                            visible: false,
                            message: "Starting import",
                            showIndicator: true,
                            showPane: true,
                            shading: true,
                            closeOnOutsideClick: false,
                        }).dxLoadPanel("instance");

                        tmpPanel.show();

                        var fd = new FormData();
                        //fd.append('files', $('#loader-file-uploader')[0].files[0]);
                        fd.append('files', files[0]);

                        $.ajax({
                            url: '../api/import/startimports?ids=' + selectedData.map(x => x.ID).join(','),
                            type: 'Post',
                            data: fd,
                            enctype: 'multipart/form-data',
                            processData: false,
                            contentType: false,
                            dataType: "json"
                        }).done(function (result) {
                            if (result.Error) {
                                sharedFunctions.showMessage(result.Error, 'Import error');
                                return;
                            } else {
                                var promise = DevExpress.ui.dialog.alert(result.Result, 'Import');
                                promise.always(function () {
                                    const url = window.location.origin + '/#/list?module=Load&Loader=' + selectedData[0].ID + '&bc:Loader=' + selectedData[0].ID;
                                    sharedFunctions.navigateToUrl(decodeURIComponent(url));
                                });
                            }
                        }).fail(function (xhr, textStatus, errorThrown) {
                            sharedFunctions.showMessage(xhr.responseText, textStatus);
                        }).always(function () {
                            tmpPanel.hide();
                            popup.hide();
                        });

                    }
                },
            },
            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: "Sluit",
                    onClick() {
                        popup.hide();
                    },
                },
            }],

    }).dxPopup('instance');

    popup.show();


    $('#loader-file-uploader').dxFileUploader({
        //selectButtonText: 'Select photo',
        //labelText: '',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        uploadMode: 'useButton',
        onValueChanged: function (args) {
            files = args.value;
        }
    });

}

window.addEventListener("customAction-Bodem", eventHandler2);
function eventHandler2(e, control) {
    fileUploadValduvis("bodem");
}

window.addEventListener("customAction-Verbeter", eventHandler3);
function eventHandler3(e, control) {
    fileUploadValduvis("verbeter");
}

window.addEventListener("customAction-VOA-Actions", eventHandler4);
function eventHandler4(e, control) {
    let url = nbbConsultSettings.Url + "?project=Valduvis";
    url += getCompanyParams();
    window.open(url, '_blank');
}

window.addEventListener("customAction-Import-Xbrl", eventHandler5);
function eventHandler5(e, control) {
    let selectedItems = $("#datagridDx1Container").dxDataGrid("instance").getSelectedRowsData();

    if (selectedItems.length > 0) {
        notify("Import has started", "info");
        setImportButton(1);

        let counter = 0;

        let url = nbbConsultSettings.Url + "api/nbb/import?project=Valduvis";
        url += getCompanyParams();

        //make an api call to XbrlConverter
        $.ajax({
            url: url,
            type: 'GET'

        }).done(function (result) {
            let htmlContent = "";
            setImportButton(0);

            //console.log(result.warnings);

            if (result.warnings.length > 0) {
                if ($("#popup-container").length === 0) {
                    $("#appContent").append('<div id="popup-container"></div>');
                }

                htmlContent = "<div id='import-warnings'>";
                htmlContent += "Following VOA's have no filings listed with NBB: <br />";

                htmlContent += "<ul>";
                $.each(result.warnings, function (index, value) {
                    htmlContent += "<li>" + value + "</li>";
                });

                htmlContent += "</ul>";
                htmlContent += "</div>";
            }

            if (result.hasErrors == true) {
                if (result.errors.length > 0) {
                    let message = "An error has occurred: ";

                    $.each(result.errors, function (index, value) {
                        if (result.errors[result.errors.length - 1] == value) {
                            message += value + ".";
                        }
                        else {
                            message += value + ", ";
                        }
                    });

                    notify(message, "error");
                    counter++;
                }
            }
            else {
                notify("The files are uploaded to the loader. Processing will take a couple of minutes.", "success");
                setTimeout(function () {
                    if (htmlContent.length > 0) {
                        $("#popup-container").dxPopup({
                            contentTemplate: htmlContent,
                            document: 'document',
                            width: 400,
                            height: 500,
                            showTitle: true,
                            title: 'Warning',
                            visible: true,
                            dragEnabled: true,
                            closeOnOutsideClick: true,
                            showCloseButton: true,
                            position: 'center',
                            shading: false,
                            toolbarItems: [
                                {
                                    widget: 'dxButton',
                                    toolbar: 'bottom',
                                    location: 'center',
                                    options: {
                                        text: 'Close',
                                        onClick() {
                                            $("#popup-container").dxPopup("hide");
                                        }
                                    }
                                }
                            ]
                        })
                    }
                }, 5000);
            }
        }).fail(function (error) {
            notify("The connection was refused. Please contact an administrator", "error");
            setImportButton(0);
        })
    }
    else {
        notify("Please select at least one VOA for import", "error");
    }
}

window.addEventListener("customAction-DuplicateStockScoring", eventHandler6);
function eventHandler6(e, control) {
    $("#datagridDx1Container").dxDataGrid("instance").option("paging.enabled", false);
    var items = $("#datagridDx1Container").dxDataGrid("instance").getDataSource().items();
    $("#datagridDx1Container").dxDataGrid("instance").option("paging.enabled", true);
    var latestYear = Math.max(...items.map(o => o.Year))
    var newYear = latestYear + 1;
    var myDialog = DevExpress.ui.dialog.custom({
        title: "Duplicate",
        messageHtml: "This will create new scores for " + newYear + " based on the " + latestYear + " records",
        buttons: [{
            text: "Continue",
            onClick: function (e) {

                var tmpPanel = $(".custom-loadpanel").dxLoadPanel({
                    shadingColor: "rgba(0,0,0,0.4)",
                    position: { of: "#ilvoBody" },
                    visible: false,
                    message: "Duplicating...",
                    showIndicator: true,
                    showPane: true,
                    shading: true,
                    closeOnOutsideClick: false
                }).dxLoadPanel("instance");

                tmpPanel.show();

                $.ajax({
                    url: '../api/project/duplicatestockscoring',
                    type: 'GET',
                    contentType: 'application/json',
                    dataType: "json"
                }).done(function (result) {
                    if (result.Error) {
                        sharedFunctions.showMessage(result.Error, "Fout");
                        return;
                    } else {
                        sharedFunctions.showToast("Success!", "success", 1000);
                        window.IlvoGrid.loadAndRenderData();

                    }

                }).fail(function (xhr, textStatus, errorThrown) {
                    sharedFunctions.showMessage(xhr.responseText, "Fout");
                })
                    .always(function () {
                        tmpPanel.hide();
                    });
            }
        },
        {
            text: "Cancel",
            onClick: function (e) {
                myDialog.hide();
            }
        },
        ]
    });

    myDialog.show();
}

window.addEventListener("customAction-Duplicate", eventHandler7);
function eventHandler7(e, control) {
    var keys = getSelectedKeys();
    if (keys.length > 0) {
        $.ajax({
            url: '../api/project/duplicategear',
            type: 'POST',
            data: JSON.stringify(keys),
            contentType: 'application/json',
            dataType: "json"
        }).done(function (result) {
            if (result.Error) {
                showMessage(result.Error, "Fout");
                return;
            } else {
                window.IlvoGrid.loadAndRenderData();
            }

        }).fail(function (xhr, textStatus, errorThrown) {
            showMessage(xhr.responseText, "Fout");
        })
            .always(function () {
                sharedFunctions.hideLoadPanel();
            });
    }
}

window.addEventListener("customAction-TripGear", function () {

    var keys = getSelectedKeys();
    if (keys.length === 0) {
        sharedFunctions.showMessage("Gelieve minstens 1 trip te selecteren", "Opgelet");
        return;
    }
    var qs = sharedFunctions.queryStringToJsonObj(window.location.hash.substr(window.location.hash.lastIndexOf('?') + 1));
    var voaId = qs.MyVessel;
    function getDataSource(url) {
        return new DevExpress.data.DataSource({
            store: new DevExpress.data.CustomStore({
                loadMode: "raw",
                load: function () {
                    var d = $.Deferred();
                    $.ajax({
                        url: url,
                        success: function (result) {
                            d.resolve(result.Result);
                        }
                    });
                    return d.promise();
                }
            })
        });
    }

    var gearDataSource = getDataSource('../api/project/getgears?voa=' + voaId);




    if ($('#popup-container').length === 0) {
        $('#appContent').append('<div id="popup-container"></div>');
    }

    const popupContentTemplate = function () {
        return $('<div>').append($(`<p>Kies een vistuig uit de onderstaande lijst die je aan de geselecteerde trip(s) wilt koppelen.</p><div id="gearDdl"></div>`));
    };
    const popup = $('#popup-container').dxPopup({
        contentTemplate: popupContentTemplate,
        width: 400,
        height: 250,
        container: 'document',
        showTitle: true,
        title: 'Vistuig instellen',
        visible: false,
        dragEnabled: true,
        closeOnOutsideClick: false,
        showCloseButton: false,
        position: 'center',
        shading: true,
        shadingColor: 'rgba(245, 40, 145, 0)',
        toolbarItems: [
            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: "Opslaan",
                    onClick() {

                        var gearid = $('#gearDdl').dxSelectBox('instance').option('value');

                        if (!gearid) {
                            sharedFunctions.showMessage("Gelieve een vistuig te selecteren", "Opgelet");
                            return;
                        }

                        $.ajax({
                            url: '../api/project/settripgear?voaid=' + voaId + '&gearid=' + gearid,
                            type: 'Post',
                            data: JSON.stringify(keys),
                            contentType: 'application/json',
                            dataType: "json"
                        }).done(function (result) {
                            if (result.Error) {
                                sharedFunctions.showMessage(result.Error, 'Fout');
                                return;
                            } else {
                                location.reload();
                            }
                        }).fail(function (xhr, textStatus, errorThrown) {
                            sharedFunctions.showMessage(xhr.responseText, "Fout");
                        }).always(function () {
                            tmpPanel.hide();
                            popup.hide();
                        });
                    },
                },
            },
            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: "Sluit",
                    onClick() {
                        popup.hide();
                    },
                },
            }],
        onShown: function (e) {
            $('#gearDdl').dxSelectBox({
                dataSource: gearDataSource,
                displayExpr: 'Display',
                valueExpr: 'ID',
                placeholder: 'Kies een vistuig',
                showClearButton: true,
            });


        }
    }).dxPopup('instance');

    popup.show();

});

function getCompanyParams() {
    var selectedItems = $("#datagridDx1Container").dxDataGrid("instance").getSelectedRowsData();
    let companiesUrl = "";

    if (selectedItems.length > 0) {
        companiesUrl = "&company=";
        $.each(selectedItems, function (index, value) {
            companiesUrl += value.CompanyNumber.replace("BE", "");;
            if (value != selectedItems[selectedItems.length - 1]) {
                companiesUrl += ",";
            }
        });

        return companiesUrl;
    }
    else {
        return "";
    }
}

function setImportButton(number) {
    let importButton = $('div[eventstring="Import-Xbrl"]');
    importButton.removeClass();

    if (number == 1) {
        importButton.addClass('customaction appbar-button secondary appbarDisabled');
        importButton.html('<span>Loading data...</span>')
        importButton.css("pointer-events", "none");
    }
    else {
        importButton.addClass('customaction appbar-button secondary');
        importButton.html('<span>Import year-1</span>')
        importButton.css("pointer-events", "auto");
    }
}

function notify(message, type, offset = "0 0", displayTime = 5000) {
    DevExpress.ui.notify({
        message: message,
        type: type,
        displayTime: displayTime,
        height: 'auto',
        position: {
            my: "center",
            at: "center",
            offset: offset
        },
    });
}

window.duplicateRecords = function () {
    var keys = sharedFunctions.getSelectedKeys();
    if (keys.length > 0) {
        $.ajax({
            url: '../api/admin/duplicaterecords?currentModule=' + currentModule,
            type: 'POST',
            data: JSON.stringify(keys),
            contentType: 'application/json',
            dataType: "json"
        }).done(function (result) {
            if (result.Error) {
                sharedFunctions.showMessage(result.Error, "Fout");
                return;
            } else {
                window.IlvoGrid.loadAndRenderData();
            }

        }).fail(function (xhr, textStatus, errorThrown) {
            sharedFunctions.showMessage(xhr.responseText, "Fout");
        })
            .always(function () {
                sharedFunctions.hideLoadPanel();
            });
    }
}

window.duplicateRecordsInto = function (navProp, targetIds) {
    var keys = getSelectedKeys();
    var sourceIds = JSON.stringify(keys);
    var navPr = navProp;
    var targets = JSON.stringify(targetIds);
    if (keys.length > 0) {
        $.ajax({
            url: '../api/admin/duplicaterecordsinto?currentModule=' + currentModule,
            type: 'POST',
            data: JSON.stringify({
                sourceIds: keys,
                navProp: navProp,
                targetIds: targetIds
            }),
            contentType: 'application/json',
            dataType: "json"
        }).done(function (result) {
            if (result.Error) {
                sharedFunctions.showMessage(result.Error, "Fout");
                return;
            } else {
                window.IlvoGrid.loadAndRenderData();
            }

        }).fail(function (xhr, textStatus, errorThrown) {
            sharedFunctions.showMessage(xhr.responseText, "Fout");
        })
            .always(function () {
                sharedFunctions.hideLoadPanel();
            });
    }
}

function findFormField(items, name) {
    var obj = "";
    if (items[0].items) {
        for (var i = 0; i < items.length; i++) {
            var tmp = items[i].items.find(x => x.dataField === name);
            if (tmp) {
                obj = tmp;
                break;
            }
        }
    } else {
        obj = items.find(x => x.dataField === name);
    }
    return obj;
}

function getSelectedKeys() {
    var selection = getSelectedData();
    if (selection.length === 0) return [];
    return selection.map(x => x.ID);
}

function getSelectedData() {
    var grid = $("#datagridDx1Container").dxDataGrid("instance");
    if (grid === undefined) return [];
    return grid.getSelectedRowsData();
}
